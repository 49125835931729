<script>
    export let previewLink;
</script>

<iframe class="previewFrame" title="" src={previewLink} allowfullscreen/>

<style>
.previewFrame {
    width: 90vw;
    height: 65vh;
}
</style>