<script>
  import {tweened} from 'svelte/motion';
  import {cubicOut} from 'svelte/easing';
  import Value from '../Common/Value.svelte'
  import {SS_HOST} from '../../network/queues/scoresaber/page-queue'
  import {getHeadsetForHMD} from "../../utils/scoresaber/format";

  export let rank;
  export let country;
  export let countryRank;
  export let countryRankTotal;
  export let showCountryTotal = false;
  export let inline = true;

  export let hmd = null;

  const currentRank = tweened(rank, {
    duration: 500,
    easing: cubicOut,
  });

  const currentCountryRank = tweened(countryRank, {
    duration: 500,
    easing: cubicOut,
  });

  $: {
    currentRank.set(rank);
  }

  $: {
    currentCountryRank.set(countryRank);
  }

  $: headset = hmd != null ? getHeadsetForHMD(hmd) : null;
</script>

<span class="val">
	<i class="fas fa-globe-americas"></i>
	<strong class="value">
		<Value value={$currentRank} prefix="#" zero="-" digits={0}/>
	</strong>

  {#if headset}
        <img src={'/assets/' + headset.icon + ".svg"}
             alt={headset.name}
             title={headset.name}
             style="width: 1.2em; filter: {headset.color}"
        />
	{/if}
</span>

{#if country}
<span class="val" style="display:{inline ? 'inline' : 'block'};">
	<img src={`${SS_HOST}/imports/images/flags/${country}.png`} alt=""/>
	<strong class="value"
          title={!showCountryTotal && country && $currentCountryRank && countryRankTotal ? `#${$currentCountryRank} / ${countryRankTotal}` : ''}>
		<Value value={$currentCountryRank} prefix="#" zero="-" digits={0}/>
    {#if showCountryTotal}<Value value={countryRankTotal} prefix="/" zero="-" digits={0}/>{/if}
	</strong>
</span>
{/if}

<style>
    .val {
        display: flex;
        align-items: center;
				justify-content: center;
    }

    .val > *:not(:last-child) {
        margin-right: .25em;
    }
</style>